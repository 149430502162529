import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Accordion, Card, Button, Navbar, Nav } from "react-bootstrap"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Swiper from "@components/swiper/swiper"
import marked from "marked"
import "./templates.scss"

class TrainingsTemplate extends React.Component {
  render() {
    const { post } = this.props.data
    const { previous, next } = this.props.pageContext
    // console.log(previous, next)
    return (
      <Layout>
        <SEO title={post.title} description={post.subHeading} />
        <div class="page-headline training-headline">
          <div class="container">
            <div class="section-heading text-center">
                <h1>{post.title}</h1>
                <h4 className="text-white mt-0">{post.area}</h4>
                <h5 className="text-white">Beginning - {post.beginningDate}</h5>
                <h5 className="text-white"
                    dangerouslySetInnerHTML={{
                      __html: marked(post.subHeading !== null ? post.subHeading : ""),
                    }}
                  />
                {post.applyNowLink !== null ? (
                  <div className="py-4">
                    <a className="btn btn-primary arrow" href={post.applyNowLink}>Apply Now</a>
                  </div>
                ) : ""}
            </div>
          </div>
        </div>
        <div className="content-menu">
          <Container className="px-5 px-md-4">
            <Navbar expand="lg">
              <Navbar.Brand>Quick Links</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-auto">
                  <Nav.Link href="#event-schedule">Event</Nav.Link>
                  <Nav.Link href="#event-pricing">Pricing</Nav.Link>
                  <Nav.Link href="#training-event-schedule">Schedule</Nav.Link>
                  <Nav.Link href="#event-curriculum">Curriculum</Nav.Link>
                  <Nav.Link href="#event-terms">Terms</Nav.Link>
                  <Nav.Link href="#event-testimonial">Testimonials</Nav.Link>
                </Nav>
                
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
        <div className="page-content training-post single-post pt-0">
        <section id="admission" className="component bg-white">
          <Container className="px-5 px-md-4">
            <div className="row">
              <div className="col-md-11 mx-auto">
                <div className="content text-center text-white bg-orange py-5 px-5">
                  <h2 className="mb-4">Admission Requirements:</h2>
                  <p>THE NARM PRACTITIONER TRAINING  is an advanced clinical training designed to enhance the skills of professionals who work with developmental trauma.  The training is intended for mental health practitioners with an active practice so the material presented in each module can be applied and developed. </p>
                  <p>Graduate trainees and interns are welcome to apply.  Other health care professionals or educators working with complex trauma may be considered. This clinical training is not appropriate for those solely seeking a personal growth experience.</p>
                  <p>Applications are approved on a case-by-case basis, and may include a personal interview with a NARM faculty member.</p>
                </div>
              </div>
            </div>
          </Container>
        </section>
        {post.trainingSchedules !== null && post.trainingSchedules.eventSchedule !== null ? (
          <section id="event-schedule" className="component bg-primary">
            <Container className="px-5 px-md-4">
              <h2 className="mb-4 text-white text">Training Schedule</h2>
              <div className="row">
                <div className="col-md-6">
                  <div className="text-white"
                    dangerouslySetInnerHTML={{
                      __html: marked(post.trainingSchedules.eventSchedule)
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-image-container">
                <div className="event-image" style={{
                  backgroundImage: `url(${post.trainingSchedules !== null ? post.trainingSchedules.eventsBanner.asset.fluid.src : "https://placeimg.com/640/480/people"})`
                }}/>
              </div>
            </Container>
          </section>

        ) : ""}
        {post.location !== null && post.location.eventLocationMap !== null ? (
          <section id="event-location" className="component bg-dark-light">
            <Container className="px-5 px-md-4">
              <div className="row">
                <div className="col-md-6">
                  <iframe src={post.location.eventLocationMap} width="100%" height={450} frameBorder={0} style={{border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} />
                </div>
                <div className="col-md-6 pl-3 pl-5">
                  <div className="mt-2 mt-md-5">
                    <h2>Location</h2>
                    <div className="location-name"
                      dangerouslySetInnerHTML={{
                        __html: marked(post.location.eventLocationAddress)
                      }}
                    />
                  </div>
                </div>
              </div>
            </Container>
          </section>
        ) : ""}
        {post.certificate !== null ? (
          <section id="event-certificate" className="component">
            <Container className="px-5 px-md-4">
              <h2 className="mb-4 text-center">{post.certificate.certificateTitle}</h2>
               <div
                  dangerouslySetInnerHTML={{
                    __html: marked(post.certificate.eventListCertificates)
                  }}
                />
            </Container>
          </section>
        ) : ""}
        
        <section className="component bg-primary">
          <Container className="px-5 px-md-4">
            <div className="row">
              <div className="col-md-11 mx-auto">
                <div className="text-center">
                  <h2 className="mb-4 text-white">Do you need CEUs?​​​​​​​</h2>
                  <p className="h4 font-weight-normal text-white">30 CEUs per module: Available for psychologists, psychotherapists, marriage and family therapists, social workers and personal counselors.  Additional cost for CEUs.</p>
                </div>
              </div>
            </div>
            
          </Container>
        </section>
        {post.schedule !== null ? (
           <section id="training-event-schedule" className="component">
            <Container className="px-5 px-md-4">
              <h2 className="mb-4 text-center">{post.schedule.scheduleTitle}</h2>
              <div className="mb-5">
                <img className="img-fluid" src={post.schedule.scheduleBanner.asset.fixed.src} alt=""/>  
              </div>
              
              {post.schedule.scheduleList.map((item, index) => (
                <Accordion key={index}>
                    <Card className="mx-0"> 
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                          {item.title}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={index}>
                        <Card.Body className="p-5">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: marked(item.description)
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                </Accordion>
              ))}
              
            </Container>
          </section>
        ) : ""}
       {post.pricing !== null ? (
          <section id="event-pricing" className="component bg-orange">
            <Container>
              <h2 className="mb-4 text-center text-white">{post.pricing.title}</h2>
              <div className="row justify-content-center">
                {post.pricing.pricingList.map((item, index) => (
                  <div className="col-md-6 col-lg-4" key={index}>
                    <div className="card bg-white shadow mx-2 h-100">
                      <div className="card-header text-center">
                        <h5 className="card-title text-primary">{item.title}</h5>
                      </div>
                      <div className="card-body text-center pb-5">
                        <h3 className="text-orange mb-0">{item.price}</h3>
                        <p className="text-muted">{item.label}</p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: marked(item.description)
                          }}
                        />
                        <div className="mt-4">
                          <a href={item.applyNowLink} className="btn btn-primary">Apply Now</a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Container>
          </section>
       ) : ""}
       {post.curriculum.length ? (
          <section id="event-curriculum" className="component bg-white">
            <Container className="px-5 px-md-4">
              <h2 className="mb-4 text-center">Course Curriculum</h2>
              {post.curriculum.map((item, index) => (
                <Accordion key={index}>
                    <Card className="mx-0"> 
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                          {item.title}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={index}>
                        <Card.Body className="p-5">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: marked(item.description)
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                </Accordion>
              ))}
              
            </Container>
          </section>
        ) : ""}
        {post.terms !== null ? (
          <section id="event-terms" className="component bg-dark-light">
            <Container className="px-5 px-md-4">
              <h2 className="mb-4 text-center">{post.terms.title}</h2>
               <div
                  dangerouslySetInnerHTML={{
                    __html: marked(post.terms.description)
                  }}
                />
            </Container>
          </section>
        ) : ""}
        {post.testimonials.length ? (
          <section id="event-testimonial" className="component">
            <Container className="px-5 px-md-4">
              <h2 className="mb-4 text-center">Testimonials</h2>
               <div className="row">
                 <div className="col-md-10 mx-auto">
                   <Swiper slidesPerView={1} autoplay={true}>
                    {post.testimonials.map((item, index) => (             
                      <div className="swiper-slide" key={index}>
                        <div className="testimonial-content text-center">
                          <p>{item.description}</p>
                          <div className="row align-items-center justify-content-center">
                            {item.headshot !== null ? (
                              <div className="col-auto pr-0">
                                <img src={item.headshot.asset.fixed.src} alt={item.author} className="img-fluid rounded-circle"/>
                              </div>
                             ) : ""}
                            <div className="col-auto text-left">
                              <h5 className="mb-0">{item.author}</h5>
                              <p className="mb-0">{item.position}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Swiper>
                 </div>
               </div>
               
            </Container>
          </section>
        ) : ""}
         
            <div class="blog-nav mt-5">
             <Container className="px-5 px-md-4">
             <hr/>
              <div class="row mt-4">
                <div class="col-md-6 text-md-left px-5 px-md-3">
                  {previous ? (
                    <div>
                      <span class="font-weight-bold text-uppercase text-muted d-block small">
                        Previous
                      </span>
                      <Link to={`/trainings/${previous.slug.current}`}>{previous.title}</Link>
                    </div>
                  ) : null}
                </div>
                <div class="col-md-6 text-md-right px-5 px-md-3">
                  {next ? (
                    <div>
                      <span class="font-weight-bold text-uppercase text-muted d-block small">
                        next
                      </span>
                      <Link to={`/trainings/${next.slug.current}`}>{next.title}</Link>
                    </div>
                  ) : null}
                </div>
              </div>
              </Container>
            </div>
          
        </div>
      </Layout>
    )
  }
}

export default TrainingsTemplate

export const trainingsQuery = graphql`
  query trainingsQueryTemplateQuery($id: String!) {
    post: sanityTraning(id: { eq: $id }) {
      id
      title
      area
      subHeading
      beginningDate(formatString: "MMMM DD, YYYY")
      applyNowLink
      trainingSchedules {
       eventSchedule
       eventsBanner {
          asset {
            fluid {
              src
            }
          }
        }
      }
      pricing {
        title
        additionalInfo
        pricingList {
          title
          description
          price
          label
          applyNowLink
        }
      }
      location {
        eventLocationMap
        eventLocationAddress
      }
      certificate {
        certificateTitle
        eventListCertificates
      }
      testimonials {
        description
        position
        headshot {
          asset {
            fixed(width: 60, height: 60) {
              src
            }
          }
        }
        author
      }
      terms {
        description
        title
      }
      curriculum {
        description
        title
      }
      schedule {
        scheduleTitle
        scheduleList {
          description
          title
        }
        scheduleBanner {
          asset {
            fixed(width: 1200, height: 585) {
             src
            }
          }
        }
      }
    }
  }
`
